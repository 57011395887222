import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
  Widgets: [
    ...shopifyDawnGenericDefaults.Widgets.filter((w) => w.name !== 'VerifyFitment'),
    {
      name: 'VerifyFitment',
      location: { selector: '#cm-verify-fitment', class: 'cm_vehicle-widget__verify-fitment_container' },
      template: 'fitmentSearch/verifyFitment',
      initCollapsed: true,
      columnBreakpoint: 9999,
    },
  ],
};
